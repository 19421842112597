import { useContext } from "react";
import AppContext from "../AppContext";

const ConditionalLink = ({ link, className, children }) => {
  const { screenWidth } = useContext(AppContext);

  if (screenWidth >= 1024) return <div className={className}>{children}</div>;
  else
    return (
      <a href={link} target="_blank" rel="noreferrer" className={className}>
        {children}
      </a>
    );
};

export default ConditionalLink;
