import { ArrowLink } from "../utils/renderSvg";

const About = () => {
  return (
    <>
      <p className="mb-4">
        I’m a{" "}
        <strong className="font-medium text-slate-200">
          Full Stack Web Developer
        </strong>{" "}
        with a passion for building{" "}
        <strong className="font-medium text-slate-200">
          scalable web applications
        </strong>{" "}
        and delivering{" "}
        <strong className="font-medium text-slate-200">
          user-focused solutions
        </strong>
        . With expertise in the{" "}
        <strong className="font-medium text-slate-200">MERN stack</strong>{" "}
        (MongoDB, Express.js, React.js, Node.js), I excel at transforming{" "}
        <strong className="font-medium text-slate-200">
          complex requirements
        </strong>{" "}
        into{" "}
        <strong className="font-medium text-slate-200">
          intuitive, high-performance web applications
        </strong>
        .
      </p>
      <p className="mb-4">
        I have strong skills in{" "}
        <strong className="font-medium text-slate-200">
          front-end and back-end development
        </strong>
        , including{" "}
        <strong className="font-medium text-slate-200">
          HTML5, CSS3, JavaScript
        </strong>
        , and{" "}
        <strong className="font-medium text-slate-200">RESTful APIs</strong>. I
        specialize in creating{" "}
        <strong className="font-medium text-slate-200">
          responsive web designs
        </strong>
        , optimizing for{" "}
        <strong className="font-medium text-slate-200">performance</strong> and
        enhancing{" "}
        <strong className="font-medium text-slate-200">user experience</strong>.
      </p>
      <p className="mb-4">
        I thrive in{" "}
        <strong className="font-medium text-slate-200">
          collaborative environments
        </strong>{" "}
        and am always eager to learn and adapt to new challenges and
        technologies. Let's connect to explore how I can bring value to your
        projects and help you achieve your goals!
      </p>
      <div className="mt-8">
        <a
          className="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-teal-300 focus-visible:text-teal-30 group/link text-base"
          href="https://drive.google.com/file/d/1AV4Q--YFp3OoNpDmfh31uGK-UBg7uKZI/view?usp=sharing"
          target="_blank"
          rel="noreferrer noopener"
          aria-label="View Full Resume (opens in a new tab)"
        >
          <span>
            View Full Resume
            <ArrowLink />
          </span>
        </a>
      </div>
    </>
  );
};

export default About;
