import Section from "./section";
import { useContext } from "react";
import AppContext from "../AppContext";

const Main = () => {
  const { sections } = useContext(AppContext);
  return (
    <main className="pt-24 lg:py-24 lg:w-1/2">
      {sections.map((section, index) => {
        const { name, component } = section;
        return <Section key={index} name={name} component={component} />;
      })}
    </main>
  );
};

export default Main;
