import { useContext } from "react";
import AppContext from "../AppContext";

const UnderLay = () => (
  <div className="absolute -inset-x-6 -inset-y-4 z-0 hidden lg:block rounded-md lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"></div>
);

const SectionRow = ({ link, className, children }) => {
  const { screenWidth } = useContext(AppContext);

  if (link && screenWidth >= 1024)
    return (
      <a href={link} target="_blank" rel="noreferrer" className={className}>
        <UnderLay />
        {children}
      </a>
    );
  else
    return (
      <div className={className}>
        <UnderLay />
        {children}
      </div>
    );
};

export default SectionRow;
