import "./App.css";
import AppContext from "./AppContext";
import Header from "./components/header";
import Main from "./components/main";
import SpecialUnderlay from "./components/specialUnderlay";
import About from "./components/about";
import Experience from "./components/experience";
import Projects from "./components/projects";
import { useEffect, useRef, useState } from "react";

const SECTIONS = [
  { name: "about", component: About },
  {
    name: "experience",
    component: Experience,
  },
  {
    name: "projects",
    component: Projects,
  },
];

function App() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const sectionRefs = useRef({
    about: null,
    experience: null,
    projects: null,
  });

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const updateCoordinates = (e) => {
    if (screenWidth >= 1024) setCoordinates({ x: e.clientX, y: e.clientY });
  };

  return (
    <AppContext.Provider
      value={{
        sections: SECTIONS,
        sectionRefs: sectionRefs.current,
        screenWidth,
      }}
    >
      <div
        className="app-wrapper relative bg-slate-900 text-slate-400 antialiased selection:bg-teal-300 selection:text-teal-900"
        onClick={(e) => updateCoordinates(e)}
        onMouseMove={(e) => updateCoordinates(e)}
      >
        <SpecialUnderlay coordinates={coordinates} />
        <div className="font-sans xl:container mx-auto py-12 md:py-20 lg:py-0 px-6 md:px-12 lg:px-24 lg:flex lg:gap-4">
          <Header />
          <Main />
        </div>
      </div>
    </AppContext.Provider>
  );
}

export default App;
