import { useContext } from "react";
import AppContext from "../AppContext";

const SpecialUnderlay = ({ coordinates: { x, y } }) => {
  const { screenWidth } = useContext(AppContext);
  return (
    <div
      className="fixed inset-0 pointer-events-none z-30 transition duration-300"
      style={{
        background: `radial-gradient(
          600px ${
            screenWidth >= 1024 ? `at ${x}px ${y}px` : "circle at 0px 0px"
          },
          rgba(29, 78, 216, 0.15),
          transparent 80%
        )`,
      }}
    ></div>
  );
};

export default SpecialUnderlay;
