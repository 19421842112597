import { useEffect, useRef, useState } from "react";

const LazyImage = ({ imageName, alt }) => {
  const [src, setSrc] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      });
    });

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isVisible) {
      // Dynamically import the image when it becomes visible
      import(`../assets/images/${imageName}`)
        .then((image) => {
          setSrc(image.default);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [isVisible, imageName]);

  return (
    <div
      ref={imgRef}
      className="max-w-[200px] sm:w-[21%] md:w-1/4 lg:w-[30%] xl:w-1/4 z-10"
    >
      {src ? (
        <img
          src={src}
          alt={alt}
          width={200}
          height={48}
          className="rounded border-2 border-slate-200/10"
        />
      ) : (
        <div className="animate-pulse flex items-center justify-center h-20 bg-gray-300 rounded dark:bg-gray-700">
          <svg
            className="w-10 h-10 text-gray-200 dark:text-gray-600"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
          </svg>
        </div>
      )}
    </div>
  );
};

export default LazyImage;
