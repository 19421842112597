import { ArrowLink } from "../utils/renderSvg";
import ConditionalLink from "./conditionalLink";
import SectionRow from "./sectionRow";

const EXPERIENCES = [
  {
    company: {
      name: "Etra Group Ltd",
      link: "https://etra.group/",
    },
    designation: "Front End Developer",
    start: "2023",
    end: "present",
    summary:
      "UK-based marketing firm where I currently work as a front-end developer, leading the front-end development of multiple websites focused on social media growth services, driving user engagement and delivering responsive, visually compelling experiences.",
  },
  {
    company: {
      name: "Agnovate Consulting (Pvt) Ltd",
      link: "http://new.agnovate.com/",
    },
    designation: "MERN Full Stack Developer",
    start: "2023 (MAR — AUG)",
    summary:
      "A company that specializes in consultancy for agro-processors. I secured a 6-month contract with them, during which I successfully contributed to the development of an MVP for field monitoring at a sugar mill.",
  },
  {
    company: {
      name: "Khaleef Technologies",
      link: "https://khaleef.com/",
    },
    designation: "Software Engineer (MERN)",
    start: "2022",
    end: "2023",
    summary:
      "Product based software company where I developed a lead-generating landing page for Du telecom's CRM. Additionally, I engineered a headless CMS with Next.js to manage MySQL databases for their flagship product, Cricwick. Furthermore, I replicated the cricwick.net website using Angular v14, showcasing versatility in frontend development across different frameworks.",
  },
  {
    company: {
      name: "ArhamSoft (Pvt) Ltd",
      link: "https://www.arhamsoft.com/",
    },
    designation: "Junior Software Engineer (MERN)",
    start: "2021",
    end: "2022",
    summary:
      "Service based software company where I developed full stack web applications from scratch using MERN stack. Moreover, I implemented feature enhancements and addressed issues across various already existing codebases utilizing the MERN stack.",
  },
];

const Experience = () => {
  return (
    <>
      {EXPERIENCES.map((exp, index) => (
        <SectionRow
          key={index}
          link={exp?.company?.link}
          className="relative mb-12 sm:flex sm:gap-8 md:gap-4 group lg:hover:!opacity-100 lg:group-hover/section:opacity-50 transition-all lg:cursor-pointer"
        >
          <div className="text-xs uppercase mt-1 mb-2 text-slate-500 font-semibold max-w-[200px] sm:w-[21%] md:w-1/4 lg:w-[30%] xl:w-1/4 z-10">
            {exp.start}
            {exp.end && ` — ${exp.end}`}
          </div>
          <div className="flex-1 z-10">
            {exp.company && (
              <ConditionalLink
                link={exp.company.link}
                className={
                  "text-slate-200 font-medium leading-snug group/link hover:text-teal-300 lg:group-hover:text-teal-300 focus-visible:text-teal-300 lg:group-focus-visible:text-teal-300"
                }
              >
                {exp.company.name}
                <ArrowLink />
              </ConditionalLink>
            )}
            <div className="text-slate-500 font-medium mb-2">
              {exp.designation}
            </div>
            <p className="text-sm leading-normal">{exp.summary}</p>
          </div>
        </SectionRow>
      ))}
    </>
  );
};

export default Experience;
